<template>
    <div class="top-box">
        <div class="home">
            <div class="box">
                <div class="map-box">
                    <img src="../assets/image/map_bg.png" class="map-bg" alt="">
                    <div class="cover-box1">
                        <img src="../assets/image/map_cover1.png" class="map-cover1" alt="">
                    </div>
                    <div class="cover-box">
                        <div class="cover-origin">
                            <img src="http://oss.3dicp.com/9364fdba179cf9cae1e7b43044aaca7f" class="map-cover" alt="">
                        </div>
                    </div>
                </div>
                <div class="data_vivew_center">
                    <DataVivewCity :lines="lines"></DataVivewCity>
                </div>
            </div>

            <div class="head-box">
                <div class="left-side">
                    <div class="firm">
                        <img data-v-339361de="" src="../assets/image/logo.png" alt="" class="logo" />
                        <div class="head-title">华印三维大数据监控系统</div>
                    </div>

                    <div class="right-side">
                        <div class="date">{{ date }} {{ time }}</div>
                        <div class="weather-box">
                            <div class="city">{{ weatherCity }}</div>
                            <div class="weather">{{ weatherType }}</div>
                            <div class="temperature">{{ weatherLow }}~{{ weatherHigh }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="total-box">
                <div class="total-item">
                    <div class="total-title">零件生产总数</div>
                    <div class="total-num">
                        <!-- <dv-digital-flop :config="numConfig1" :style="{ width: '120px', height: '50px' }" /> -->
                        <div class="font">{{yield_num}}</div>
                        <span class="unit">件</span>
                    </div>
                </div>
                <div class="total-item">
                    <div class="total-title">合格交付率</div>
                    <div class="total-num">
                        <!-- <dv-digital-flop :config="numConfig" :style="{ width: '100px', height: '50px' }" /> -->
                        <div class="font">{{qualified}}</div>
                        <span class="unit">%</span>
                    </div>
                </div>
                <div class="total-item">
                    <div class="total-title">及时交付率</div>
                    <div class="total-num">
                        <!-- <dv-digital-flop :config="numConfig2" :style="{ width: '100px', height: '50px' }" /> -->
                        <div class="font">{{delivery}}</div>
                        <span class="unit">%</span>
                    </div>
                </div>
            </div>
            <div class="data-box">
                <div class="order-box item-box">
                    <div class="item-head">用户下单情况</div>
                    <dv-scroll-board class="order-table" :config="orderConfig" style="width:300px;height:450px" />
                </div>

                <div class="items-box">
                    <div class="trend-box item-box">
                        <div class="item-head">销售业绩趋势</div>
                        <div id="trend-chart">
                            <div id="container"></div>
                        </div>
                    </div>
                    <div class="area-box item-box">
                        <div class="item-head">客户分布地区</div>
                        <div class="list ">
                            <div class="list-item " v-for="(item, index) in areaList">
                                <div>
                                    <div class="label rotate-90-horizontal-bck"
                                        :style="'animation-delay: ' + (Math.floor((index + 1) / 2) * 0.2) + 's;'">
                                        <div>
                                            <span class="index">{{ item.sort }}</span>{{ item.city }}
                                        </div>
                                        <div>{{ item.ratio }}%</div>
                                    </div>
                                    <div class="num-bar">
                                        <div class="num" :style="{ width: `${item.ratio}%` }">
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="industry-box item-box">
                        <div class="item-head">行业分布情况</div>
                        <div id="industry-chart" style="width:500px ;height:190px">

                        </div>
                    </div>

                    <div class="material-box item-box">
                        <div class="item-head">热门打印材料TOP10</div>
                        <dv-scroll-board class="material-table" :config="config" style="width:500px;height:220px" />
                    </div>

                </div>
            </div>
        </div>

    </div>

</template>
<script>
import Charts from '@jiaminghi/charts'
import DataVivewCity from "../components/3Dcity"
// import axios from 'axios'
// const formatter = (num) => {
    // const numberStr = num.split('.');
    // const number = numberStr[0];
    // const add = numberStr[1] || '';
    // const numbers = number.toString().split('').reverse();
    // const segs = [];

    // while (numbers.length) segs.push(numbers.splice(0, 3).join(''));
    // const str = segs.join(',').split('').reverse().join('') + (add ? `.${add}` : '');
    // return str;
// };
export default {
    name: "Panle",
    components: { DataVivewCity },
    data() {
        return {
            time: '',
            date: '',
            class: '',
            lines: [],
            weatherType: '',
            weatherCity: '',
            weatherHigh: '',
            weatherLow: '',
            qualified:'99.81',
            delivery:'98.70',
            yield_num:'',
            yield:570000,
            // numConfig: {
            //     number: [508000],
            //     formatter,
            //     toFixed: 2,
            //     textAlign: 'right',
            //     style: {
            //         fontFamily: 'Acens',
            //         fontSize: 30,
            //         fill: '#fff',
            //     },
            //     animationFrame:0
            // },
            // 零件生产总额
            // numConfig1: {
            //     number: [98.21],
            //     formatter,
            //     textAlign: 'right',
            //     style: {
            //         fontFamily: 'Acens',
            //         fontSize: 30,
            //         fill: '#fff',
            //     },
            //     animationFrame:0
            // },
            // // 及时交付率
            // numConfig2: {
            //     number: [99.70],
            //     formatter,
            //     toFixed: 2,
            //     textAlign: 'right',
            //     style: {
            //         fontFamily: 'Acens',
            //         fontSize: 30,
            //         fill: '#fff',
            //     },
            //     animationFrame:0
            // },
            config: {
                header: ['排名', '材料名称', '工艺类型', '订单比例'],
                headerBGC: 'rgba(13, 93, 255, 0.08)',
                oddRowBGC: 'transparent',
                evenRowBGC: 'transparent',
                headerHeight: 30,
                columnWidth: [80, 220, 100, 100],
                data: [
                    ['1', '国产白色树脂', 'SLA', '50.85%'],
                    ['2', '铝合金', 'SLM', '18.95%'],
                    ['3', '模具钢', 'SLM', '13.65%'],
                    ['4', '国产黄色树脂', 'SLA', '4.4%'],
                    ['5', '进口黑色尼龙', 'MJF', '3.94%'],
                    ['6', '国产黑色尼龙', 'SLS', '1.71%'],
                    ['7', '进口白色尼龙', 'SLS', '1.56%'],
                    ['8', '国产白色尼龙', 'SLS', '1.51%'],
                    ['9', '进口黑色尼龙玻纤', 'MJF', '1.28%'],
                    ['10', '316L不锈钢', 'SLM', '0.56%'],
                ],
            },
            orderConfig: {
                header: ['名称', '订单类型', '金额'],
                headerBGC: 'rgba(13, 93, 255, 0.08)',
                oddRowBGC: 'rgba(255, 255, 255, 0.1)',
                evenRowBGC: 'transparent',
                headerHeight: 30,
                columnWidth: [80, 100, 120],
                rowNum: 13,
                data: [
                    ['烟*司', '打印订单', 550],
                    ['泰*司', '打印订单', 620],
                    ['东*司', '打印订单', 180],
                    ['闲*司', '打印订单', 136],
                    ['鸟*香', '打印订单', 240],
                    ['奥*特', '打印订单', 260],
                    ['上*司', '打印订单', 3600],
                    ['厦*司', '打印订单', 80],
                    ['鸟*香', '打印订单', 144],
                    ['鸟*香', '打印订单', 356],
                    ['鸟*香', '打印订单', 330],
                    ['长*特', '打印订单', 230],
                    ['杭*司', '打印订单', 2800],
                    ['杭*司', '打印订单', 800],
                    ['杭*司', '打印订单', 220],
                    ['上*司', '打印订单', 450],
                    ['杭*司', '打印订单', 1000],
                    ['威*司', '打印订单', 1000],
                    ['厦*司', '打印订单', 130],
                    ['长*特', '打印订单', 600],
                    ['天*司', '打印订单', 335],
                    ['桂*司', '打印订单', 720],
                    ['宁*司', '打印订单', 1780],
                    ['长*司', '打印订单', 260],
                    ['杭*司', '打印订单', 1000],
                    ['杭*司', '打印订单', 100],
                    ['东*司', '打印订单', 1150],
                    ['宁*司', '打印订单', 80],
                    ['杭*司', '打印订单', 1250],
                ],
            },
            areaList: [
                { id: 1, sort: 1, city: "吉林", ratio: 36 },
                { id: 2, sort: 2, city: "上海", ratio: 25 },
                { id: 3, sort: 3, city: "北京", ratio: 19 },
                { id: 4, sort: 4, city: "浙江", ratio: 14 },
                { id: 5, sort: 5, city: "广东", ratio: 4, },
                { id: 6, sort: 6, city: "江苏", ratio: 2, },
                { id: 7, sort: 7, city: "山东", ratio: 2, },
                { id: 8, sort: 8, city: "天津", ratio: 2, },
                { id: 9, sort: 9, city: "福建", ratio: 2, },
                { id: 10, sort: 10, city: "安徽", ratio: 2, }
            ]
        }
    }, updated() {

    }, create() {

    }, methods: {
        linesData() {
            setInterval(() => {
                var cityData = [
                    "广东", "青海", "吉林", "广西", "湖南", "云南", "福建", "内蒙古", "江苏", "北京", "西藏", "安徽", "湖北", "天津", "辽宁", "河南", "陕西", "新疆", "浙江", "河北", "上海", "山东", "宁夏", "四川", "黑龙江"
                ]
                var newCityData = [];
                for (var i = 0; i < 8; i++) {
                    newCityData.push(
                        {
                            source: cityData[Math.floor(Math.random() * cityData.length)],
                            target: '杭州',
                            width: 3,
                            color: '#fff',
                            duration: [50, 65],
                            radius: 200,
                        }
                    )
                }

                this.lines = newCityData
            }, 5000)


        },
        // formatter(number) {
        //     const numbers = number.toString().split('').reverse()
        //     const segs = []

        //     while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

        //     return segs.join(',').split('').reverse().join('')
        // },
        getDateFunc() {
            let year = new Date().getFullYear();//年
            let month = new Date().getMonth() + 1;//注意！月份是从0月开始获取的，所以要+1;
            let day = new Date().getDate();//日
            let hour = new Date().getHours();//时
            let minute = new Date().getMinutes();//分
            let second = new Date().getSeconds();//秒
            //拼接日期 YYYY-MM-DD HH:mm:ss
            this.date =
                year +
                '-' +
                (month >= 10 ? month : '0' + month) +
                '-' +
                (day >= 10 ? day : '0' + day);
            this.time =
                (hour >= 10 ? hour : '0' + hour)
                + ':' +
                (minute >= 10 ? minute : '0' + minute)
                + ':' +
                (second >= 10 ? second : '0' + second);
        },
        addStyle(time) {
        },
        power(n) {
           this.formatter(this.yield)
            setInterval(()=>{
                this.yield=this.yield+1
                this.formatter(this.yield)
            },4000)
        },
        // setTime(num1,  num2) {
        //     this.numConfig1.number[0] = num1;
        //     this.numConfig1 = { ...this.numConfig1 };
        //     this.numConfig.number[0] = this.numConfig.number[0]+1;
        //     this.numConfig = { ...this.numConfig };
        //     this.numConfig2.number[0] = num3;
        //     this.numConfig2 = { ...this.numConfig2 };
        // },
        formatter(number) {
            const numbers = number.toString().split('').reverse()
            const segs = []
            while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
            this.yield_num= segs.join(',').split('').reverse().join('')
        },
        getWeather() {

            this.$axios('http://wthrcdn.etouch.cn/weather_mini?city=杭州').then((res) => {
                if (res.data) {
                    this.weatherType = res.data.data.forecast[0].type;
                    this.weatherCity = res.data.data.city;
                    this.weatherHigh = res.data.data.forecast[0].high.split(' ')[1]
                    this.weatherLow = res.data.data.forecast[0].low.split(' ')[1]
                }
            })
        }



    }, mounted() {

        var that = this
        setInterval(() => {
            that.getDateFunc();

        }, 1000);

        (function () {
            that.getWeather()
            that.linesData();
            that.power();
            const container = document.getElementById('container')
            const industry = document.getElementById('industry-chart')
            const myChart = new Charts(container)
            const industryChart = new Charts(industry)
            var option = {
                color: ['#2f89cf'],

                // 这是控制标题的

                xAxis: {
                    axisLabel: {
                        style: {
                            fill: '#fff',
                            fontSize: 15,
                        },

                    },
                    min: '20%',
                    axisTick: {
                        show: false,
                    },
                    // boundaryGap: false,
                    data: ['2021-08', '', '2021-10', '', '2021-12', '', '2022-02', '', '2022-04', '', '2022-06', ''],
                },
                legend: {
                    show: false,
                },
                yAxis: {
                    name: '万',
                    nameTextStyle: {
                        fill: '#fff',
                        fontSize: 15,

                        translate: [-15, 0],
                    },
                    data: 'value',
                    min: 0,
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        style: {
                            stroke: 'rgb(85, 105, 148)',
                        },
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        style: {
                            fontSize: 15,
                            fill: '#fff',
                        },
                    },
                },
                grid: {
                    left: 50,
                    // top: 20,
                    top: 35,
                    bottom: 25,
                    right: 0,
                },
                series: [
                    {
                        data: [2.4, 17.3, 14.3, 19.4, 25.6, 17.8, 30.2, 16.4, 27.1, 19.5, 35.2, 38.5],
                        type: 'line',
                        lineStyle: {
                            fill: 'rgb(55, 121, 253)',
                        },
                        lineArea: {
                            show: true,
                            gradient: ['rgb(13, 110, 200)', 'rgb(7, 33, 71)'],
                        },
                        label: {
                            show: true,
                            style: {
                                // fill: 'rgb(59, 122, 251)',
                                fill: '#feda5c',
                                fontSize: 14,
                            },
                        },
                        linePoint: {
                            radius: 1,
                            style: {
                                fill: 'rgb(1, 185, 255)',
                            },
                        },
                        smooth: true,
                    },
                ],

            }
            var inOption = {
                color: ['#0a73ff', '#034DBA', '#6CA3FE', '#4D7BF3', '#01347D', '#4FCCFF'],
                series: [
                    {

                        type: 'pie',
                        data: [
                            { name: '制造业', value: 32 },
                            { name: '电子产品', value: 15 },
                            { name: '教育', value: 19 },
                            { name: '文化创意', value: 7 },
                            { name: '其他', value: 27 },

                        ],
                        radius: '75%',

                        insideLabel: {
                            show: true,
                        },

                        outsideLabel: {
                            labelLineBendGap: '20%',

                            style: {
                                fill: '#fff',
                                fontSize: 14,
                            },
                        },
                    },
                ],
            }
            myChart.setOption(option)
            industryChart.setOption(inOption)


        })()
    }
}

</script>
<style>
.rotate-90-horizontal-bck {
    animation-name: rotate-90-horizontal-bck;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    -webkit-animation-name: rotate-90-horizontal-bck;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;

}
.font{
    width: auto;
    padding-left: 20px;
    font-size: 30px;
}
@-webkit-keyframes rotate-90-horizontal-bck {
    0% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0)
    }

    5.7% {
        transform: rotateX(90deg);
        -webkit-transform: rotateX(90deg)
    }

    5.71% {
        transform: rotateX(-90deg);
        -webkit-transform: rotateX(-90deg)
    }

    11.42% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg)
    }

    100% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg)
    }

}

@keyframes rotate-90-horizontal-bck {
    0% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0)
    }

    5.7% {
        transform: rotateX(90deg);
        -webkit-transform: rotateX(90deg)
    }

    5.71% {
        transform: rotateX(-90deg);
        -webkit-transform: rotateX(-90deg)
    }

    11.42% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg)
    }

    100% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg)
    }
}


.firm {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 72px
}

.data_vivew_center {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

.home {

    position: absolute;
    top: 0;
    left: 0;
    width: 1920px;
    height: 1080px;
    font-size: 14px;
    overflow: hidden;
    color: #fff;
    letter-spacing: 1px;

}



.head-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 94px;
    z-index: 100;
    background: url('http://oss.3dicp.com/692c36c63028781886fd28d14e98bd68');

}


.left-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 94px;
    padding-bottom: 10px;
}

.logo {
    height: 38px;
    margin-left: 13px;
}

.head-title {

    margin-left: 26px;
    color: unset;
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 1px;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    white-space: nowrap;
    background-image: linear-gradient(0deg, rgb(118, 195, 255) 0%, rgb(255, 255, 255) 100%);
}

.right-side {
    display: flex;
    align-items: center;
    color: #fff;
    padding-right: 20px;
    right: 0;
}

.date {
    font-size: 22.5px;
    font-weight: 400;
    text-align: justify;
}

.weather-box {
    font-size: 18px;
    display: flex;
    margin-left: 30px;
}

.weather {
    margin-left: 5px;
}

.temperature {
    margin-left: 20px;
}


.items-box {
    position: absolute;
    top: 110px;
    left: 20px;
    z-index: 10;
}

.item-box {
    margin-bottom: 25px;
}

#container {
    width: 500px;
    height: 170px;
}

.item-head {
    width: 314px;
    height: 27px;
    font-size: 16px;
    padding-left: 15px;
    color: #fff;
    line-height: 27px;
    background: url('../assets/image/item_bg.png') no-repeat;
    background-size: contain;
}

.light {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 2px;
    will-change: transform;
    background: radial-gradient(rgb(121, 218, 255) 5%, rgba(0, 0, 0, 0) 80%);
    transform: translateX(-70px);
}

.industry-bottom-box {
    position: absolute;
    left: 600px;
    bottom: 20px;
    z-index: 100;
}

.order-box {
    position: absolute;
    right: 20px;
    bottom: 15px;
    color: #fff;
    z-index: 10;
    margin-bottom: 0;
    background-color: rgba(13, 93, 255, 0.08);
}

.order-table {
    margin-top: 10px;
}


.list {
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    margin-top: 10px;
    padding-left: 15px;
}

.list-item {
    width: 230px;
    margin-right: 25px;
    margin-top: 8px;
}

.list-item:nth-child(even) {
    margin-right: 0;
}

.label {
    display: flex;
    will-change: transform;
    justify-content: space-between;
}

.index {
    width: 20px;
}

.num-bar {
    margin-top: 8px;
    height: 8px;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
}

.num {
    height: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    background: rgb(10, 115, 255);
    overflow: hidden;
}

.total-box {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    z-index: 10;
    letter-spacing: 1px;
}

.total-item {
    display: flex;

    align-items: center;
    margin-right: 20px;
}

.total-title {
    font-size: 18px;
    margin-right: 15px;
}

.total-num {
    display: flex;
    align-items: center;
    letter-spacing: 0;
}

.unit {
    font-size: 14px;
    margin-left: 5px;
}


.box {
    position: absolute;
    top: 0;
    left: -100px;
    width: 2020px;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.map-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.map-bg {
    position: absolute;
    width: 2020px;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.8;
}

.cover-box1 {
    width: 1321px;
    transform: rotate(3deg);
    position: absolute;
    opacity: 0.3;
    left: 635px;
    top: 117px;
    z-index: 10;
}

.map-cover1 {
    position: absolute;
    width: 1242px;
    height: 870px;
    top: 0;
    left: 0;
}

.cover-box {
    width: 2285px;
    height: 1316px;
    transform: translate(94px, 0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    perspective: 500px;
    perspective-origin: 50% 100%;
}

.cover-origin {
    position: absolute;
    transform: rotate3d(1, 0, 0, 3deg);
    transform-origin: 1142.5px 658px;
}

.map-cover {
    position: absolute;
    width: 2305px;
    height: 1366px;
    top: -179px;
    left: -3px;
    transform: rotate(3deg);
}
</style>
