import Vue from 'vue'
import VueRouter from 'vue-router'
import Panle from '../views/Panle'
Vue.use(VueRouter)

  const routes = [
    {
      path:'/',
      name:'Panle',
      component:Panle
    },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router