const round = [
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 10,
      left: 1426,
      top: 570,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 8,
      left: 1526,
      top: 470,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 10,
      left: 926,
      top: 370,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 917,
      top: 385,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 3,
      left: 954,
      top: 388,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 4,
      left: 864,
      top: 368,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 807,
      top: 447,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 4,
      left: 728,
      top: 489,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1087,
      top: 556,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 8,
      left: 951,
      top: 668,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1187,
      top: 596,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 4,
      left: 1487,
      top: 756,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 4,
      left: 1437,
      top: 706,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 3,
      left: 1537,
      top: 703,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 3,
      left: 1337,
      top: 503,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 3,
      left: 1437,
      top: 543,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 3,
      left: 1337,
      top: 503,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 3,
      left: 1137,
      top: 703,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1167,
      top: 603,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1367,
      top: 633,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1337,
      top: 633,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1237,
      top: 733,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1437,
      top: 433,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1537,
      top: 413,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1637,
      top: 313,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1635,
      top: 363,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1427,
      top: 563,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1327,
      top: 663,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1367,
      top: 693,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1417,
      top: 609,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1483,
      top: 667,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1419,
      top: 704,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1483,
      top: 581,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1498,
      top: 677,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1478,
      top: 751,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1459,
      top: 533,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1529,
      top: 653,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1421,
      top: 537,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1424,
      top: 776,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1486,
      top: 816,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1613,
      top: 484,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 5,
      left: 1534,
      top: 646,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1478,
      top: 667,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1462,
      top: 563,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1363,
      top: 636,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1461,
      top: 548,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1473,
      top: 578,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1408,
      top: 555,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1503,
      top: 715,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1537,
      top: 743,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1535,
      top: 753,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1432,
      top: 671,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1469,
      top: 619,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1485,
      top: 627,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1445,
      top: 550,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1393,
      top: 552,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1377,
      top: 603,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1420,
      top: 647,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1435,
      top: 593,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1460,
      top: 607,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1448,
      top: 720,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1445,
      top: 717,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1397,
      top: 527,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1460,
      top: 551,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1515,
      top: 579,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 2,
      left: 1507,
      top: 610,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1507,
      top: 573,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1452,
      top: 567,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1538,
      top: 745,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 7,
      left: 1489,
      top: 692,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 7,
      left: 1464,
      top: 551,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 7,
      left: 1546,
      top: 682,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 7,
      left: 1560,
      top: 739,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 7,
      left: 1408,
      top: 868,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 7,
      left: 1375,
      top: 878,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 7,
      left: 1501,
      top: 808,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 7,
      left: 1497,
      top: 601,
    },
    {
      color: 'rgba(170, 97, 74, 1)',
      size: 7,
      left: 1540,
      top: 582,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1478,
      top: 603,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1527,
      top: 773,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1476,
      top: 753,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1503,
      top: 793,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1484,
      top: 828,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1431,
      top: 857,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1411,
      top: 854,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1390,
      top: 859,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1388,
      top: 778,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1218,
      top: 682,
    },
    {
      color: 'rgba(7, 175, 255, 1)',
      size: 7,
      left: 1688,
      top: 441,
    },
  ];
  
  export default round;
  