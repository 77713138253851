<template>
    <div class="box">
        <div class="content-box">
            <div class="province-box">
                <div class="province-item" :style="{ left: `${item.left}px`, top: `${item.top}px` }"
                    v-for="item in province" :key="item.name">{{ item.name }}</div>
            </div>
        </div>
        <div class="circle-box">
            <div class="circle-item" :style="roundStyle(item)" v-for="(item, index) in round" :key="index"></div>
        </div>
        <div class="line-box">
            <dv-flyline-chart-enhanced :config="config" style="width:2020px;height:1080px" />
        </div>
    </div>
</template>

<script>
import points from '../views/points';
import round from '../views/round';
export default {
    name: 'DataVivewCity',
    props: {
        lines: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            config: {

            },
            province: [
                {
                    name: '云南省',
                    left: 1166,
                    top: 827,
                },
                {
                    name: '黑龙江省',
                    left: 1690,
                    top: 332,
                },
                {
                    name: '贵州省',
                    left: 1285,
                    top: 790,
                },
                {
                    name: '北京市',
                    left: 1474,
                    top: 493,
                },
                {
                    name: '河北省',
                    left: 1452,
                    top: 538,
                },
                {
                    name: '山西省',
                    left: 1391,
                    top: 558,
                },
                {
                    name: '吉林省',
                    left: 1660,
                    top: 422,
                },
                {
                    name: '宁夏回族自治区',
                    left: 1277,
                    top: 553,
                },
                {
                    name: '辽宁省',
                    left: 1604,
                    top: 469,
                },
                {
                    name: '海南省',
                    left: 1335,
                    top: 954,
                },
                {
                    name: '内蒙古自治区',
                    left: 1380,
                    top: 439,
                },
                {
                    name: '天津市',
                    left: 1492,
                    top: 532,
                },
                {
                    name: '新疆维吾尔自治区',
                    left: 903,
                    top: 452,
                },
                {
                    name: '上海市',
                    left: 1563,
                    top: 708,
                },
                {
                    name: '陕西省',
                    left: 1316,
                    top: 624,
                },
                {
                    name: '甘肃省',
                    left: 1087,
                    top: 481,
                },
                {
                    name: '安徽省',
                    left: 1485,
                    top: 689,
                },
                {
                    name: '香港特别行政区',
                    left: 1409,
                    top: 892,
                },
                {
                    name: '广东省',
                    left: 1405,
                    top: 855,
                },
                {
                    name: '河南省',
                    left: 1410,
                    top: 637,
                },
                {
                    name: '湖南省',
                    left: 1375,
                    top: 768,
                },
                {
                    name: '江西省',
                    left: 1450,
                    top: 776,
                },
                {
                    name: '四川省',
                    left: 1204,
                    top: 703,
                },
                {
                    name: '广西壮族自治区',
                    left: 1312,
                    top: 857,
                },
                {
                    name: '江苏省',
                    left: 1541,
                    top: 675,
                },
                {
                    name: '澳门特别行政区',
                    left: 1440,
                    top: 895,
                },
                {
                    name: '浙江省',
                    left: 1543,
                    top: 751,
                },
                {
                    name: '山东省',
                    left: 1506,
                    top: 588,
                },
                {
                    name: '青海省',
                    left: 1075,
                    top: 584,
                },
                {
                    name: '重庆市',
                    left: 1308,
                    top: 718,
                },
                {
                    name: '福建省',
                    left: 1501,
                    top: 817,
                },
                {
                    name: '湖北省',
                    left: 1386,
                    top: 705,
                },
                {
                    name: '西藏自治区',
                    left: 912,
                    top: 662,
                },
                {
                    name: '台湾省',
                    left: 1557,
                    top: 868,
                },
            ],
            round: round
        };
    }, watch: {
        lines() {
            this.city()
        }
    }, methods: {

        roundStyle(item) {
            return {
                backgroundColor: `${item.color}`,
                width: `${item.size}px`,
                height: `${item.size}px`,
                left: `${item.left}px`,
                top: `${item.top}px`,
            };
        },
        city() {
            this.config={}
            this.$nextTick(() => {
                this.config = {
                    relative: false,
                    points: points,
                    lines: this.lines
                    ,
                    text: {
                        show: true,
                        fontSize: 14,
                    },
                    line: {
                        duration: [50, 65],
                        width: 3,
                        color: '#fff',
                        orbitColor: 'transparent',
                        repeatCount: 1,
                        radius: 200,
                    },
                    centerPointImg: {
                        url: '../assets/image/point.png'
                    },
                    k:-1,
                }
            });
        }
        // async city() {

        //     var res = await new Promise((resolve, reject) => {
        //         var cityData = [
        //             "辽宁",
        //             "吉林", "河北", "江苏", "宁夏", "广东", "内蒙古", "新疆", "河北", "江苏",
        //             "广东", "山东", "青海", "山东", "新疆", "四川", "福建", "浙江", "吉林", "河北",
        //             "上海", "安徽", "广西", "天津", "福建", "安徽", "河南", "辽宁", "北京", "江苏",
        //             "福建", "山东", "安徽", "广东", "辽宁", "河南", "北京", "湖南", "广东", "江苏",
        //             "云南", "广东", "浙江", "北京", "内蒙古", "山东", "辽宁", "福建", "广东", "北京",
        //             "北京", "内蒙古", "广东", "浙江", "江苏", "上海", "山东", "陕西", "北京", "广东",
        //             "浙江", "西藏", "广东", "河南", "广东", "安徽", "河南", "江苏", "湖北", "山东",
        //             "天津", "辽宁", "河南", "浙江", "宁夏", "江苏", "陕西", "四川", "新疆", "广东",
        //             "浙江", "河北", "上海", "北京", "山东", "广东", "广东", "宁夏", "江苏", "四川"
        //         ]
        //         var newCityData = [];
        //         for (var i = 0; i < 8; i++) {

        //             newCityData.push(
        //                 {
        //                     source: cityData[Math.floor(Math.random() * cityData.length)],
        //                     target: '杭州',
        //                     width: 3,
        //                     color: '#fff',
        //                     duration: [30, 45],
        //                     radius: 200,

        //                 }
        //             )
        //         }
        //         resolve(newCityData);
        //     })
        //     return res;
        //     console.log(res);
        // }
    }, mounted() {

        // this.city().then((e) => {
        //     this.config.lines = e
        // })
    }
};
</script>


<style lang='less' scoped>
.box {
    position: absolute;
    top: 0;
    left: 0;
    width: 2020px;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.content-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 1920px;
    height: 1080px;
}

.province-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    color: #fff;
    opacity: 0.6;
    width: 1920px;
    height: 1080px;
}

.province-item {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
}

.circle-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 1920px;
    height: 1080px;
}

.circle-item {
    position: absolute;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    transform: scale(1);
    will-change: transform;
    animation: 4s circle-change infinite ease;
}

@keyframes circle-change {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}
</style>
