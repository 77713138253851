import img from '../assets/image/point.png';

const sourcePoint = {
  text: {
    show: false,
  },
};

const points = [
  {
    name: '杭州',
    coordinate: [1542, 725],
    icon: {
      show: true,
      src: img,
      width: 20,
      height: 20,
    },
    halo: {
      show: true,
      color: '#0a73ff',
      radius: 40,
    },
  },
  {
    name: '杭州',
    coordinate: [1542, 725],
    ...sourcePoint,
  },
  {
    name: '重庆',
    coordinate: [1279, 727],
    ...sourcePoint,
  },
  {
    name: '重庆2',
    coordinate: [1285, 738],
    ...sourcePoint,
  },
  {
    name: '重庆3',
    coordinate: [1326, 690],
    ...sourcePoint,
  },
  {
    name: '重庆4',
    coordinate: [1326, 701],
    ...sourcePoint,
  },
  {
    name: '重庆5',
    coordinate: [1314, 700],
    ...sourcePoint,
  },
  {
    name: '重庆6',
    coordinate: [1322, 693],
    ...sourcePoint,
  },
  {
    name: '重庆7',
    coordinate: [1309, 729],
    ...sourcePoint,
  },
  {
    name: '重庆8',
    coordinate: [1275, 734],
    ...sourcePoint,
  },
  {
    name: '重庆9',
    coordinate: [1326, 702],
    ...sourcePoint,
  },
  {
    name: '重庆10',
    coordinate: [1304, 710],
    ...sourcePoint,
  },
  {
    name: '浙江',
    coordinate: [1524, 758],
    ...sourcePoint,
  },
  {
    name: '浙江2',
    coordinate: [1550, 769],
    ...sourcePoint,
  },
  {
    name: '浙江3',
    coordinate: [1529, 773],
    ...sourcePoint,
  },
  {
    name: '浙江4',
    coordinate: [1564, 750],
    ...sourcePoint,
  },
  {
    name: '浙江5',
    coordinate: [1560, 736],
    ...sourcePoint,
  },
  {
    name: '浙江6',
    coordinate: [1523, 757],
    ...sourcePoint,
  },
  {
    name: '浙江7',
    coordinate: [1542, 754],
    ...sourcePoint,
  },
  {
    name: '浙江8',
    coordinate: [1546, 762],
    ...sourcePoint,
  },
  {
    name: '浙江9',
    coordinate: [1538, 784],
    ...sourcePoint,
  },
  {
    name: '浙江10',
    coordinate: [1512, 752],
    ...sourcePoint,
  },
  {
    name: '江苏',
    coordinate: [1510, 642],
    ...sourcePoint,
  },
  {
    name: '江苏2',
    coordinate: [1535, 642],
    ...sourcePoint,
  },
  {
    name: '江苏3',
    coordinate: [1525, 635],
    ...sourcePoint,
  },
  {
    name: '江苏4',
    coordinate: [1523, 664],
    ...sourcePoint,
  },
  {
    name: '江苏5',
    coordinate: [1551, 668],
    ...sourcePoint,
  },
  {
    name: '江苏6',
    coordinate: [1539, 691],
    ...sourcePoint,
  },
  {
    name: '江苏7',
    coordinate: [1527, 691],
    ...sourcePoint,
  },
  {
    name: '江苏8',
    coordinate: [1556, 689],
    ...sourcePoint,
  },
  {
    name: '江苏9',
    coordinate: [1514, 654],
    ...sourcePoint,
  },
  {
    name: '江苏10',
    coordinate: [1535, 656],
    ...sourcePoint,
  },
  {
    name: '安徽',
    coordinate: [1462, 656],
    ...sourcePoint,
  },
  {
    name: '安徽2',
    coordinate: [1481, 658],
    ...sourcePoint,
  },
  {
    name: '安徽3',
    coordinate: [1493, 654],
    ...sourcePoint,
  },
  {
    name: '安徽4',
    coordinate: [1487, 675],
    ...sourcePoint,
  },
  {
    name: '安徽5',
    coordinate: [1475, 693],
    ...sourcePoint,
  },
  {
    name: '安徽6',
    coordinate: [1491, 705],
    ...sourcePoint,
  },
  {
    name: '安徽7',
    coordinate: [1475, 708],
    ...sourcePoint,
  },
  {
    name: '安徽8',
    coordinate: [1489, 722],
    ...sourcePoint,
  },
  {
    name: '安徽9',
    coordinate: [1504, 726],
    ...sourcePoint,
  },
  {
    name: '安徽10',
    coordinate: [1516, 714],
    ...sourcePoint,
  },
  {
    name: '江西',
    coordinate: [1459, 753],
    ...sourcePoint,
  },
  {
    name: '江西2',
    coordinate: [1446, 753],
    ...sourcePoint,
  },
  {
    name: '江西3',
    coordinate: [1440, 779],
    ...sourcePoint,
  },
  {
    name: '江西4',
    coordinate: [1439, 807],
    ...sourcePoint,
  },
  {
    name: '江西5',
    coordinate: [1447, 825],
    ...sourcePoint,
  },
  {
    name: '江西6',
    coordinate: [1462, 799],
    ...sourcePoint,
  },
  {
    name: '江西7',
    coordinate: [1476, 775],
    ...sourcePoint,
  },
  {
    name: '江西8',
    coordinate: [1482, 754],
    ...sourcePoint,
  },
  {
    name: '江西9',
    coordinate: [1454, 780],
    ...sourcePoint,
  },
  {
    name: '江西10',
    coordinate: [1494, 760],
    ...sourcePoint,
  },
  {
    name: '福建',
    coordinate: [1483, 819],
    ...sourcePoint,
  },
  {
    name: '福建2',
    coordinate: [1505, 803],
    ...sourcePoint,
  },
  {
    name: '福建3',
    coordinate: [1503, 782],
    ...sourcePoint,
  },
  {
    name: '福建4',
    coordinate: [1498, 789],
    ...sourcePoint,
  },
  {
    name: '福建5',
    coordinate: [1516, 804],
    ...sourcePoint,
  },
  {
    name: '福建6',
    coordinate: [1530, 802],
    ...sourcePoint,
  },
  {
    name: '福建7',
    coordinate: [1494, 834],
    ...sourcePoint,
  },
  {
    name: '福建8',
    coordinate: [1481, 836],
    ...sourcePoint,
  },
  {
    name: '福建9',
    coordinate: [1482, 857],
    ...sourcePoint,
  },
  {
    name: '福建10',
    coordinate: [1490, 857],
    ...sourcePoint,
  },
  {
    name: '湖南',
    coordinate: [1378, 766],
    ...sourcePoint,
  },
  {
    name: '湖南2',
    coordinate: [1380, 789],
    ...sourcePoint,
  },
  {
    name: '湖南3',
    coordinate: [1384, 808],
    ...sourcePoint,
  },
  {
    name: '湖南4',
    coordinate: [1351, 796],
    ...sourcePoint,
  },
  {
    name: '湖南5',
    coordinate: [1401, 814],
    ...sourcePoint,
  },
  {
    name: '湖南6',
    coordinate: [1404, 765],
    ...sourcePoint,
  },
  {
    name: '湖南7',
    coordinate: [1381, 820],
    ...sourcePoint,
  },
  {
    name: '湖南8',
    coordinate: [1409, 747],
    ...sourcePoint,
  },
  {
    name: '湖南9',
    coordinate: [1370, 789],
    ...sourcePoint,
  },
  {
    name: '湖南10',
    coordinate: [1387, 785],
    ...sourcePoint,
  },
  {
    name: '湖北',
    coordinate: [1400, 705],
    ...sourcePoint,
  },
  {
    name: '湖北2',
    coordinate: [1383, 689],
    ...sourcePoint,
  },
  {
    name: '湖北3',
    coordinate: [1411, 687],
    ...sourcePoint,
  },
  {
    name: '湖北4',
    coordinate: [1349, 717],
    ...sourcePoint,
  },
  {
    name: '湖北5',
    coordinate: [1398, 687],
    ...sourcePoint,
  },
  {
    name: '湖北6',
    coordinate: [1395, 685],
    ...sourcePoint,
  },
  {
    name: '湖北7',
    coordinate: [1362, 659],
    ...sourcePoint,
  },
  {
    name: '湖北8',
    coordinate: [1367, 671],
    ...sourcePoint,
  },
  {
    name: '湖北9',
    coordinate: [1392, 686],
    ...sourcePoint,
  },
  {
    name: '湖北10',
    coordinate: [1428, 721],
    ...sourcePoint,
  },
  {
    name: '广东',
    coordinate: [1406, 847],
    ...sourcePoint,
  },
  {
    name: '广东2',
    coordinate: [1434, 858],
    ...sourcePoint,
  },
  {
    name: '广东3',
    coordinate: [1428, 861],
    ...sourcePoint,
  },
  {
    name: '广东4',
    coordinate: [1415, 842],
    ...sourcePoint,
  },
  {
    name: '广东5',
    coordinate: [1382, 877],
    ...sourcePoint,
  },
  {
    name: '广东6',
    coordinate: [1413, 840],
    ...sourcePoint,
  },
  {
    name: '广东7',
    coordinate: [1462, 855],
    ...sourcePoint,
  },
  {
    name: '广东8',
    coordinate: [1396, 874],
    ...sourcePoint,
  },
  {
    name: '广东9',
    coordinate: [1414, 870],
    ...sourcePoint,
  },
  {
    name: '广东10',
    coordinate: [1454, 874],
    ...sourcePoint,
  },
  {
    name: '山西',
    coordinate: [1383, 556],
    ...sourcePoint,
  },
  {
    name: '山西2',
    coordinate: [1384, 532],
    ...sourcePoint,
  },
  {
    name: '山西3',
    coordinate: [1400, 525],
    ...sourcePoint,
  },
  {
    name: '山西4',
    coordinate: [1401, 514],
    ...sourcePoint,
  },
  {
    name: '山西5',
    coordinate: [1414, 497],
    ...sourcePoint,
  },
  {
    name: '山西6',
    coordinate: [1387, 535],
    ...sourcePoint,
  },
  {
    name: '山西7',
    coordinate: [1374, 586],
    ...sourcePoint,
  },
  {
    name: '山西8',
    coordinate: [1383, 593],
    ...sourcePoint,
  },
  {
    name: '山西9',
    coordinate: [1386, 541],
    ...sourcePoint,
  },
  {
    name: '山西10',
    coordinate: [1388, 532],
    ...sourcePoint,
  },
  {
    name: '陕西',
    coordinate: [1332, 612],
    ...sourcePoint,
  },
  {
    name: '陕西2',
    coordinate: [1343, 555],
    ...sourcePoint,
  },
  {
    name: '陕西3',
    coordinate: [1351, 554],
    ...sourcePoint,
  },
  {
    name: '陕西4',
    coordinate: [1351, 525],
    ...sourcePoint,
  },
  {
    name: '陕西5',
    coordinate: [1351, 525],
    ...sourcePoint,
  },
  {
    name: '陕西6',
    coordinate: [1313, 657],
    ...sourcePoint,
  },
  {
    name: '陕西7',
    coordinate: [1322, 637],
    ...sourcePoint,
  },
  {
    name: '陕西8',
    coordinate: [1334, 584],
    ...sourcePoint,
  },
  {
    name: '陕西9',
    coordinate: [1332, 568],
    ...sourcePoint,
  },
  {
    name: '陕西10',
    coordinate: [1336, 601],
    ...sourcePoint,
  },
  {
    name: '河南',
    coordinate: [1414, 626],
    ...sourcePoint,
  },
  {
    name: '河南2',
    coordinate: [1429, 631],
    ...sourcePoint,
  },
  {
    name: '河南3',
    coordinate: [1430, 610],
    ...sourcePoint,
  },
  {
    name: '河南4',
    coordinate: [1425, 667],
    ...sourcePoint,
  },
  {
    name: '河南5',
    coordinate: [1398, 665],
    ...sourcePoint,
  },
  {
    name: '河南6',
    coordinate: [1410, 635],
    ...sourcePoint,
  },
  {
    name: '河南7',
    coordinate: [1417, 651],
    ...sourcePoint,
  },
  {
    name: '河南8',
    coordinate: [1406, 630],
    ...sourcePoint,
  },
  {
    name: '河南9',
    coordinate: [1422, 618],
    ...sourcePoint,
  },
  {
    name: '河南10',
    coordinate: [1438, 654],
    ...sourcePoint,
  },
  {
    name: '河北',
    coordinate: [1444, 561],
    ...sourcePoint,
  },
  {
    name: '河北2',
    coordinate: [1453, 522],
    ...sourcePoint,
  },
  {
    name: '河北3',
    coordinate: [1448, 512],
    ...sourcePoint,
  },
  {
    name: '河北4',
    coordinate: [1450, 475],
    ...sourcePoint,
  },
  {
    name: '河北5',
    coordinate: [1492, 469],
    ...sourcePoint,
  },
  {
    name: '河北6',
    coordinate: [1515, 500],
    ...sourcePoint,
  },
  {
    name: '河北7',
    coordinate: [1474, 549],
    ...sourcePoint,
  },
  {
    name: '河北8',
    coordinate: [1439, 576],
    ...sourcePoint,
  },
  {
    name: '河北9',
    coordinate: [1448, 524],
    ...sourcePoint,
  },
  {
    name: '河北10',
    coordinate: [1443, 473],
    ...sourcePoint,
  },
  {
    name: '山东',
    coordinate: [1488, 602],
    ...sourcePoint,
  },
  {
    name: '山东2',
    coordinate: [1536, 581],
    ...sourcePoint,
  },
  {
    name: '山东3',
    coordinate: [1494, 570],
    ...sourcePoint,
  },
  {
    name: '山东4',
    coordinate: [1501, 607],
    ...sourcePoint,
  },
  {
    name: '山东5',
    coordinate: [1464, 615],
    ...sourcePoint,
  },
  {
    name: '山东6',
    coordinate: [1506, 573],
    ...sourcePoint,
  },
  {
    name: '山东7',
    coordinate: [1461, 611],
    ...sourcePoint,
  },
  {
    name: '山东8',
    coordinate: [1476, 579],
    ...sourcePoint,
  },
  {
    name: '山东9',
    coordinate: [1483, 602],
    ...sourcePoint,
  },
  {
    name: '山东10',
    coordinate: [1510, 607],
    ...sourcePoint,
  },
  {
    name: '甘肃',
    coordinate: [1240, 618],
    ...sourcePoint,
  },
  {
    name: '甘肃2',
    coordinate: [1231, 607],
    ...sourcePoint,
  },
  {
    name: '甘肃3',
    coordinate: [1230, 574],
    ...sourcePoint,
  },
  {
    name: '甘肃4',
    coordinate: [1093, 505],
    ...sourcePoint,
  },
  {
    name: '甘肃5',
    coordinate: [1109, 459],
    ...sourcePoint,
  },
  {
    name: '甘肃6',
    coordinate: [1109, 445],
    ...sourcePoint,
  },
  {
    name: '甘肃7',
    coordinate: [1217, 517],
    ...sourcePoint,
  },
  {
    name: '甘肃8',
    coordinate: [1217, 608],
    ...sourcePoint,
  },
  {
    name: '甘肃9',
    coordinate: [1256, 615],
    ...sourcePoint,
  },
  {
    name: '甘肃10',
    coordinate: [1062, 461],
    ...sourcePoint,
  },
  {
    name: '云南',
    coordinate: [1181, 818],
    ...sourcePoint,
  },
  {
    name: '云南2',
    coordinate: [1201, 829],
    ...sourcePoint,
  },
  {
    name: '云南3',
    coordinate: [1144, 787],
    ...sourcePoint,
  },
  {
    name: '云南4',
    coordinate: [1136, 779],
    ...sourcePoint,
  },
  {
    name: '云南5',
    coordinate: [1224, 829],
    ...sourcePoint,
  },
  {
    name: '云南6',
    coordinate: [1213, 808],
    ...sourcePoint,
  },
  {
    name: '云南7',
    coordinate: [1217, 800],
    ...sourcePoint,
  },
  {
    name: '云南8',
    coordinate: [1147, 794],
    ...sourcePoint,
  },
  {
    name: '云南9',
    coordinate: [1163, 863],
    ...sourcePoint,
  },
  {
    name: '云南10',
    coordinate: [1165, 827],
    ...sourcePoint,
  },
  {
    name: '西藏',
    coordinate: [1012, 716],
    ...sourcePoint,
  },
  {
    name: '西藏2',
    coordinate: [1045, 720],
    ...sourcePoint,
  },
  {
    name: '西藏3',
    coordinate: [1104, 709],
    ...sourcePoint,
  },
  {
    name: '西藏4',
    coordinate: [906, 642],
    ...sourcePoint,
  },
  {
    name: '西藏5',
    coordinate: [795, 629],
    ...sourcePoint,
  },
  {
    name: '西藏6',
    coordinate: [920, 578],
    ...sourcePoint,
  },
  {
    name: '西藏7',
    coordinate: [842, 601],
    ...sourcePoint,
  },
  {
    name: '西藏8',
    coordinate: [919, 576],
    ...sourcePoint,
  },
  {
    name: '西藏9',
    coordinate: [915, 715],
    ...sourcePoint,
  },
  {
    name: '西藏10',
    coordinate: [1022, 700],
    ...sourcePoint,
  },
  {
    name: '新疆',
    coordinate: [895, 483],
    ...sourcePoint,
  },
  {
    name: '新疆2',
    coordinate: [783, 518],
    ...sourcePoint,
  },
  {
    name: '新疆3',
    coordinate: [1031, 394],
    ...sourcePoint,
  },
  {
    name: '新疆4',
    coordinate: [980, 314],
    ...sourcePoint,
  },
  {
    name: '新疆5',
    coordinate: [864, 522],
    ...sourcePoint,
  },
  {
    name: '新疆6',
    coordinate: [867, 431],
    ...sourcePoint,
  },
  {
    name: '新疆7',
    coordinate: [941, 438],
    ...sourcePoint,
  },
  {
    name: '新疆8',
    coordinate: [1009, 398],
    ...sourcePoint,
  },
  {
    name: '新疆9',
    coordinate: [840, 557],
    ...sourcePoint,
  },
  {
    name: '新疆10',
    coordinate: [913, 533],
    ...sourcePoint,
  },
  {
    name: '青海',
    coordinate: [1125, 563],
    ...sourcePoint,
  },
  {
    name: '青海2',
    coordinate: [1125, 563],
    ...sourcePoint,
  },
  {
    name: '青海3',
    coordinate: [1063, 623],
    ...sourcePoint,
  },
  {
    name: '青海4',
    coordinate: [1159, 616],
    ...sourcePoint,
  },
  {
    name: '青海5',
    coordinate: [1088, 634],
    ...sourcePoint,
  },
  {
    name: '青海6',
    coordinate: [1143, 565],
    ...sourcePoint,
  },
  {
    name: '青海7',
    coordinate: [982, 612],
    ...sourcePoint,
  },
  {
    name: '青海8',
    coordinate: [1087, 595],
    ...sourcePoint,
  },
  {
    name: '青海9',
    coordinate: [1158, 565],
    ...sourcePoint,
  },
  {
    name: '青海10',
    coordinate: [1173, 543],
    ...sourcePoint,
  },
  {
    name: '内蒙古',
    coordinate: [1233, 473],
    ...sourcePoint,
  },
  {
    name: '内蒙古2',
    coordinate: [1167, 446],
    ...sourcePoint,
  },
  {
    name: '内蒙古3',
    coordinate: [1388, 473],
    ...sourcePoint,
  },
  {
    name: '内蒙古4',
    coordinate: [1472, 416],
    ...sourcePoint,
  },
  {
    name: '内蒙古5',
    coordinate: [1551, 299],
    ...sourcePoint,
  },
  {
    name: '内蒙古6',
    coordinate: [1600, 246],
    ...sourcePoint,
  },
  {
    name: '内蒙古7',
    coordinate: [1531, 268],
    ...sourcePoint,
  },
  {
    name: '内蒙古8',
    coordinate: [1431, 424],
    ...sourcePoint,
  },
  {
    name: '内蒙古9',
    coordinate: [1288, 471],
    ...sourcePoint,
  },
  {
    name: '内蒙古10',
    coordinate: [1415, 437],
    ...sourcePoint,
  },
  {
    name: '吉林',
    coordinate: [1692, 428],
    ...sourcePoint,
  },
  {
    name: '吉林2',
    coordinate: [1655, 407],
    ...sourcePoint,
  },
  {
    name: '吉林3',
    coordinate: [1618, 374],
    ...sourcePoint,
  },
  {
    name: '吉林4',
    coordinate: [1716, 437],
    ...sourcePoint,
  },
  {
    name: '吉林5',
    coordinate: [1656, 459],
    ...sourcePoint,
  },
  {
    name: '吉林6',
    coordinate: [1646, 412],
    ...sourcePoint,
  },
  {
    name: '吉林7',
    coordinate: [1634, 392],
    ...sourcePoint,
  },
  {
    name: '吉林8',
    coordinate: [1661, 406],
    ...sourcePoint,
  },
  {
    name: '吉林9',
    coordinate: [1630, 407],
    ...sourcePoint,
  },
  {
    name: '吉林10',
    coordinate: [1742, 434],
    ...sourcePoint,
  },
  {
    name: '辽宁',
    coordinate: [1609, 454],
    ...sourcePoint,
  },
  {
    name: '辽宁2',
    coordinate: [1576, 458],
    ...sourcePoint,
  },
  {
    name: '辽宁3',
    coordinate: [1553, 473],
    ...sourcePoint,
  },
  {
    name: '辽宁4',
    coordinate: [1606, 459],
    ...sourcePoint,
  },
  {
    name: '辽宁5',
    coordinate: [1601, 499],
    ...sourcePoint,
  },
  {
    name: '辽宁6',
    coordinate: [1563, 471],
    ...sourcePoint,
  },
  {
    name: '辽宁7',
    coordinate: [1475, 708],
    ...sourcePoint,
  },
  {
    name: '辽宁8',
    coordinate: [1609, 450],
    ...sourcePoint,
  },
  {
    name: '辽宁9',
    coordinate: [1616, 484],
    ...sourcePoint,
  },
  {
    name: '辽宁10',
    coordinate: [1625, 446],
    ...sourcePoint,
  },
  {
    name: '广西',
    coordinate: [1355, 825],
    ...sourcePoint,
  },
  {
    name: '广西2',
    coordinate: [1329, 844],
    ...sourcePoint,
  },
  {
    name: '广西3',
    coordinate: [1328, 839],
    ...sourcePoint,
  },
  {
    name: '广西4',
    coordinate: [1313, 833],
    ...sourcePoint,
  },
  {
    name: '广西5',
    coordinate: [1339, 838],
    ...sourcePoint,
  },
  {
    name: '广西6',
    coordinate: [1339, 870],
    ...sourcePoint,
  },
  {
    name: '广西7',
    coordinate: [1293, 884],
    ...sourcePoint,
  },
  {
    name: '广西8',
    coordinate: [1368, 842],
    ...sourcePoint,
  },
  {
    name: '广西9',
    coordinate: [1285, 861],
    ...sourcePoint,
  },
  {
    name: '广西10',
    coordinate: [1320, 856],
    ...sourcePoint,
  },
  {
    name: '海南',
    coordinate: [1336, 947],
    ...sourcePoint,
  },
  {
    name: '海南2',
    coordinate: [1333, 948],
    ...sourcePoint,
  },
  {
    name: '海南3',
    coordinate: [1335, 945],
    ...sourcePoint,
  },
  {
    name: '海南4',
    coordinate: [1329, 963],
    ...sourcePoint,
  },
  {
    name: '海南5',
    coordinate: [1342, 947],
    ...sourcePoint,
  },
  {
    name: '海南6',
    coordinate: [1332, 947],
    ...sourcePoint,
  },
  {
    name: '海南7',
    coordinate: [1335, 944],
    ...sourcePoint,
  },
  {
    name: '海南8',
    coordinate: [1329, 960],
    ...sourcePoint,
  },
  {
    name: '海南9',
    coordinate: [1337, 947],
    ...sourcePoint,
  },
  {
    name: '海南10',
    coordinate: [1327, 956],
    ...sourcePoint,
  },
  {
    name: '黑龙江',
    coordinate: [1665, 270],
    ...sourcePoint,
  },
  {
    name: '黑龙江2',
    coordinate: [1716, 346],
    ...sourcePoint,
  },
  {
    name: '黑龙江3',
    coordinate: [1734, 356],
    ...sourcePoint,
  },
  {
    name: '黑龙江4',
    coordinate: [1717, 388],
    ...sourcePoint,
  },
  {
    name: '黑龙江5',
    coordinate: [1635, 351],
    ...sourcePoint,
  },
  {
    name: '黑龙江6',
    coordinate: [1782, 349],
    ...sourcePoint,
  },
  {
    name: '黑龙江7',
    coordinate: [1697, 375],
    ...sourcePoint,
  },
  {
    name: '黑龙江8',
    coordinate: [1696, 320],
    ...sourcePoint,
  },
  {
    name: '黑龙江9',
    coordinate: [1667, 274],
    ...sourcePoint,
  },
  {
    name: '黑龙江10',
    coordinate: [1720, 359],
    ...sourcePoint,
  },
  {
    name: '四川',
    coordinate: [1214, 678],
    ...sourcePoint,
  },
  {
    name: '四川2',
    coordinate: [1273, 689],
    ...sourcePoint,
  },
  {
    name: '四川3',
    coordinate: [1161, 690],
    ...sourcePoint,
  },
  {
    name: '四川4',
    coordinate: [1210, 653],
    ...sourcePoint,
  },
  {
    name: '四川5',
    coordinate: [1158, 673],
    ...sourcePoint,
  },
  {
    name: '四川6',
    coordinate: [1129, 648],
    ...sourcePoint,
  },
  {
    name: '四川7',
    coordinate: [1203, 687],
    ...sourcePoint,
  },
  {
    name: '四川8',
    coordinate: [1156, 726],
    ...sourcePoint,
  },
  {
    name: '四川9',
    coordinate: [1183, 732],
    ...sourcePoint,
  },
  {
    name: '四川10',
    coordinate: [1241, 740],
    ...sourcePoint,
  },
  {
    name: '宁夏',
    coordinate: [1285, 517],
    ...sourcePoint,
  },
  {
    name: '宁夏2',
    coordinate: [1272, 568],
    ...sourcePoint,
  },
  {
    name: '宁夏3',
    coordinate: [1283, 542],
    ...sourcePoint,
  },
  {
    name: '宁夏4',
    coordinate: [1270, 568],
    ...sourcePoint,
  },
  {
    name: '宁夏5',
    coordinate: [1276, 563],
    ...sourcePoint,
  },
  {
    name: '宁夏6',
    coordinate: [1273, 553],
    ...sourcePoint,
  },
  {
    name: '宁夏7',
    coordinate: [1273, 568],
    ...sourcePoint,
  },
  {
    name: '宁夏8',
    coordinate: [1265, 554],
    ...sourcePoint,
  },
  {
    name: '宁夏9',
    coordinate: [1274, 573],
    ...sourcePoint,
  },
  {
    name: '宁夏10',
    coordinate: [1279, 541],
    ...sourcePoint,
  },
  {
    name: '贵州',
    coordinate: [1298, 788],
    ...sourcePoint,
  },
  {
    name: '贵州2',
    coordinate: [1289, 768],
    ...sourcePoint,
  },
  {
    name: '贵州3',
    coordinate: [1303, 778],
    ...sourcePoint,
  },
  {
    name: '贵州4',
    coordinate: [1296, 768],
    ...sourcePoint,
  },
  {
    name: '贵州5',
    coordinate: [1303, 816],
    ...sourcePoint,
  },
  {
    name: '贵州6',
    coordinate: [1254, 807],
    ...sourcePoint,
  },
  {
    name: '贵州7',
    coordinate: [1252, 792],
    ...sourcePoint,
  },
  {
    name: '贵州8',
    coordinate: [1301, 754],
    ...sourcePoint,
  },
  {
    name: '贵州9',
    coordinate: [1252, 790],
    ...sourcePoint,
  },
  {
    name: '贵州10',
    coordinate: [1269, 795],
    ...sourcePoint,
  },
  {
    name: '北京',
    coordinate: [1465, 500],
    ...sourcePoint,
  },
  {
    name: '北京1',
    coordinate: [1467, 500],
    ...sourcePoint,
  },
  {
    name: '北京2',
    coordinate: [1478, 490],
    ...sourcePoint,
  },
  {
    name: '北京3',
    coordinate: [1473, 505],
    ...sourcePoint,
  },
  {
    name: '北京4',
    coordinate: [1477, 496],
    ...sourcePoint,
  },
  {
    name: '北京5',
    coordinate: [1469, 501],
    ...sourcePoint,
  },
  {
    name: '北京6',
    coordinate: [1480, 497],
    ...sourcePoint,
  },
  {
    name: '北京7',
    coordinate: [1479, 488],
    ...sourcePoint,
  },
  {
    name: '北京8',
    coordinate: [1471, 501],
    ...sourcePoint,
  },
  {
    name: '北京9',
    coordinate: [1474, 505],
    ...sourcePoint,
  },
  {
    name: '北京10',
    coordinate: [1476, 499],
    ...sourcePoint,
  },
  {
    name: '天津',
    coordinate: [1494, 518],
    ...sourcePoint,
  },
  {
    name: '天津2',
    coordinate: [1494, 513],
    ...sourcePoint,
  },
  {
    name: '天津3',
    coordinate: [1494, 520],
    ...sourcePoint,
  },
  {
    name: '天津4',
    coordinate: [1489, 517],
    ...sourcePoint,
  },
  {
    name: '天津5',
    coordinate: [1494, 521],
    ...sourcePoint,
  },
  {
    name: '天津6',
    coordinate: [1492, 521],
    ...sourcePoint,
  },
  {
    name: '天津7',
    coordinate: [1489, 514],
    ...sourcePoint,
  },
  {
    name: '天津8',
    coordinate: [1489, 517],
    ...sourcePoint,
  },
  {
    name: '天津9',
    coordinate: [1491, 526],
    ...sourcePoint,
  },
  {
    name: '天津10',
    coordinate: [1491, 516],
    ...sourcePoint,
  },
  {
    name: '上海',
    coordinate: [1568, 705],
    ...sourcePoint,
  },
  {
    name: '上海2',
    coordinate: [1568, 711],
    ...sourcePoint,
  },
  {
    name: '上海3',
    coordinate: [1567, 705],
    ...sourcePoint,
  },
  {
    name: '上海4',
    coordinate: [1570, 706],
    ...sourcePoint,
  },
  {
    name: '上海5',
    coordinate: [1563, 704],
    ...sourcePoint,
  },
  {
    name: '上海6',
    coordinate: [1571, 704],
    ...sourcePoint,
  },
  {
    name: '上海7',
    coordinate: [1571, 705],
    ...sourcePoint,
  },
  {
    name: '上海8',
    coordinate: [1568, 708],
    ...sourcePoint,
  },
  {
    name: '上海9',
    coordinate: [1570, 708],
    ...sourcePoint,
  },
  {
    name: '上海10',
    coordinate: [1572, 707],
    ...sourcePoint,
  },
  {
    name: '澳门',
    coordinate: [1418, 889],
    ...sourcePoint,
  },
  {
    name: '澳门2',
    coordinate: [1408, 896],
    ...sourcePoint,
  },
  {
    name: '澳门3',
    coordinate: [1407, 880],
    ...sourcePoint,
  },
  {
    name: '澳门4',
    coordinate: [1417, 885],
    ...sourcePoint,
  },
  {
    name: '澳门5',
    coordinate: [1411, 887],
    ...sourcePoint,
  },
  {
    name: '澳门6',
    coordinate: [1420, 883],
    ...sourcePoint,
  },
  {
    name: '澳门7',
    coordinate: [1416, 888],
    ...sourcePoint,
  },
  {
    name: '澳门8',
    coordinate: [1421, 887],
    ...sourcePoint,
  },
  {
    name: '澳门9',
    coordinate: [1413, 894],
    ...sourcePoint,
  },
  {
    name: '澳门10',
    coordinate: [1413, 889],
    ...sourcePoint,
  },
  {
    name: '香港',
    coordinate: [1413, 890],
    ...sourcePoint,
  },
  {
    name: '香港2',
    coordinate: [1424, 887],
    ...sourcePoint,
  },
  {
    name: '香港3',
    coordinate: [1415, 888],
    ...sourcePoint,
  },
  {
    name: '香港4',
    coordinate: [1422, 888],
    ...sourcePoint,
  },
  {
    name: '香港5',
    coordinate: [1416, 894],
    ...sourcePoint,
  },
  {
    name: '香港6',
    coordinate: [1421, 891],
    ...sourcePoint,
  },
  {
    name: '香港7',
    coordinate: [1418, 893],
    ...sourcePoint,
  },
  {
    name: '香港8',
    coordinate: [1426, 887],
    ...sourcePoint,
  },
  {
    name: '香港9',
    coordinate: [1428, 892],
    ...sourcePoint,
  },
  {
    name: '香港10',
    coordinate: [1421, 896],
    ...sourcePoint,
  },
  {
    name: '台湾',
    coordinate: [1556, 868],
    ...sourcePoint,
  },
  {
    name: '台湾2',
    coordinate: [1558, 855],
    ...sourcePoint,
  },
  {
    name: '台湾3',
    coordinate: [1546, 881],
    ...sourcePoint,
  },
  {
    name: '台湾4',
    coordinate: [1551, 879],
    ...sourcePoint,
  },
  {
    name: '台湾5',
    coordinate: [1564, 856],
    ...sourcePoint,
  },
  {
    name: '台湾6',
    coordinate: [1550, 878],
    ...sourcePoint,
  },
  {
    name: '台湾7',
    coordinate: [1556, 859],
    ...sourcePoint,
  },
  {
    name: '台湾8',
    coordinate: [1557, 884],
    ...sourcePoint,
  },
  {
    name: '台湾9',
    coordinate: [1556, 866],
    ...sourcePoint,
  },
  {
    name: '台湾10',
    coordinate: [1549, 886],
    ...sourcePoint,
  },
  
];

export default points;
