<template>
  <div id="app">
    <router-view></router-view>>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = '0 0';
  },
};
</script>

<style>

body{
  margin: 0;
  background: #010307;
  width: 100%;
  overflow: auto;
}
</style>

